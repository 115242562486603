@import 'sms-colors';

ngx-charts-pie-chart .chart-legend .horizontal-legend li {
    display: block;
}

ngx-charts-pie-chart .chart-legend .legend-label-text,
combo-chart-component .chart-legend .legend-label-text {
    color: $black-text;
}

ngx-charts-pie-chart .chart-legend .legend-labels {
    background-color: rgba(0, 0, 0, 0);
}

combo-chart-component .chart-legend .legend-labels {
    white-space: initial;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.gridline-path-vertical {
    display: none;
}