// @include input-placeholder {
//   color: $placeholder-color;
//   font-style: italic;
//  }
@mixin input-placeholder {
  #{if(&, "&", "*")}.placeholder {
    @content;
  }

  #{if(&, "&", "*")}:-moz-placeholder {
    @content;
  }

  #{if(&, "&", "*")}::-moz-placeholder {
    @content;
  }

  #{if(&, "&", "*")}:-ms-input-placeholder {
    @content;
  }

  #{if(&, "&", "*")}::-webkit-input-placeholder {
    @content;
  }
}

// .header-right {
//   padding: 17px 30px;
//   margin-left: $sidenav-width;
//   @include mq('medium'){
//     margin-left: 50px;
//     padding: 18px 20px;
//   }
//   @include mq('smallest'){
//     margin-left: 20px;
//   }
// }
$breakpoints: (
  "large": 1254px,
  "medium": 991px,
  "small": 767px,
  "smallest": 575.98px,
  "extra-small": 320px
);

@mixin mq($width) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @media only screen and (max-width: $width) {
      @content;
    }
  }
}

// Side-nav slides left and right
@mixin nav-transition($all, $ease-in) {
  transition: all $all ease-in $ease-in;
  -webkit-transition: all $all ease-in $ease-in;
  -moz-transition: all $all ease-in $ease-in;
  -ms-transition: all $all ease-in $ease-in;
  -o-transition: all $all ease-in $ease-in;

}

// Side-nav slides left and right
@mixin subform-transition($height, $ease-in) {
  transition: height $height ease-in $ease-in;
  -webkit-transition: height $height ease-in $ease-in;
  -moz-transition: height $height ease-in $ease-in;
  -ms-transition: height $height ease-in $ease-in;
  -o-transition: height $height ease-in $ease-in;
}

@mixin appearance($val: none) {
  -webkit-appearance: $val;
  -moz-appearance: $val;
  appearance: $val;
}

// @include pseudo;
// @include pseudo(inline-block, relative, '');
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

// @include background-opacity($sms-blue, 0.05)
@mixin background-opacity($color, $opacity: 0.3) {
  background: rgba($color, $opacity);
}

//@include box-shadow(0, 1px, 1px, $sms-blue, 0.075, inset);
@mixin box-shadow($top, $left, $blur, $color, $opacity, $inset: '') {
  box-shadow:$top $left $blur rgba($color, $opacity) #{$inset};
}

@mixin notification-gradient($color) {
    color: mix(#000, $color, 48%);
    @include notification-background(mix(#fff, $color, 80%));
}

@mixin notification-background($color) {
  background: $color linear-gradient(180deg, mix($white-color, $color, 15%), $color) repeat-x;
}