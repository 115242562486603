@import 'sms-colors';

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 25px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $input-background-color;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: $white-background;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $cta-green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round {
  border-radius: 34px;
}

input:checked + .slider.disabled, .slider.disabled {
  background-color: $input-disabled-background-color;
}
