@import 'buttons';
@import 'sms-colors';
@import 'mixins';

.card {
  min-height: 59px;
  background: $white-accent;
  border: 1px solid #d0d1cc;
  border-radius: 3px;
  margin-bottom: 20px;
  @include box-shadow(0, 2px, 2px, $black-text, 0.08);
}

h3.card-title {
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: .9rem;
}

.card-title .fa {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  padding: 10px;
  font-size: 1rem;
}

.card-subtitle {
  font-size: .75rem;
  font-weight: 600;
  color: #000;

}

.card-subtitleCounter {
    font-size: .75rem;
    font-weight: 600;
    color: #FFC107;
    background-color: $card-text;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
    margin: 2px;
}

a.card:hover, a.card:focus {
  text-decoration: none;
  position: relative;
  top: -1px;
}

.card-deck {
  height: 100%;
}

.card-deck.company-data, .card-deck.settings {
  a.card, span.card {
    box-sizing: border-box;
    min-width: 28%;
    margin-bottom: 20px;

    @include mq('large') {
      min-width: 28%;
    }

    @include mq('medium') {
      min-width: 28%;
    }

    @include mq('small') {
      min-width: 27%;
    }

    @include mq('smallest') {
      min-width: 100%;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
    }
  }
}

div.notifications div.card-deck .card {
    background: #fff;
    border: 1px solid #d0d1cc;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px !important;
    box-shadow: 0 2px 2px rgba(0,0,0,.08);
    flex: 1 0 0%;
    &.small-card {
      max-width: 220px;
      margin-right: 0px;
      @include mq('smallest') {
        max-width: inherit;
      }
    }
}
.card-deck {
    margin-left: -15px;
    margin-right: -15px;

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;


    .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-left:15px;
      margin-right: 15px;
      margin-bottom: 10px; // Override the default

    }
  }
}
.card-body{
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
div.notifications div.card-deck span.large-text {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1;
    color: $card-text;
    &.green-text {
      color: $cta-green;
    }
    &.red-text {
      color: $jjk-red;
    }
}

div.notifications div.card-deck {
    height: auto;
}

jjk-notifications-bar .row {
    margin-bottom: -25px;
    display: flex;
}

@media (min-width: 1545px){
    .d-xxl-none {
        display: none !important;
    }
    .d-xxl-block {
        display: block !important;
    }
}
