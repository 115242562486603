$content-margin-top-large: 85px;
$content-margin-top-medium: 85px;
$content-margin-top-small: 51px;
$footer-height: 280px;
$header-height: 85px;
$header-height-medium: 85px;
$header-height-small: 60px;
$header-right-bar-width: 115px;
$mobile-nav-height: 38px;
$mobile-nav-height-small: 44px;
$mobile-nav-pad-height-medium: 10px;
$mobile-nav-pad-height-small: 5px;
$mobile-nav-border-top-small: 1px;
$sidenav-width: 250px;
$sidenav-triangle-width: 10px;
$sidenav-triangle-height: 20px;

$sidenav-top-offset-medium: calc(#{$header-height-medium} + #{$mobile-nav-height} + (2 * #{$mobile-nav-pad-height-medium}));
$sidenav-top-offset-small: calc(#{$content-margin-top-small} + #{$mobile-nav-height-small} + (2 * #{$mobile-nav-pad-height-small}) + #{$mobile-nav-border-top-small});
$sidenav-height-medium: calc(100vh - #{$sidenav-top-offset-medium});