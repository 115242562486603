@import 'sms-colors';

html {
    //ag-grid-custom-toolpanel
    --mdc-list-list-item-label-text-weight: 600 !important;
    --mdc-list-list-item-label-text-size: small !important;
    --mdc-list-list-item-label-text-color: #347AB7 !important;
    --mdc-list-list-item-focus-label-text-color: #347AB7 !important;
    --mdc-list-list-item-hover-label-text-color: #347AB7 !important;
    --mdc-list-list-item-label-text-font: Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol !important;
    --mdc-list-list-item-label-text-tracking: 0.04em;
    --mdc-list-list-item-one-line-container-height: 35px;

    --mdc-dialog-subhead-font: Poppins;
    --mdc-dialog-subhead-tracking: 0.04em;
}

div.mat-mdc-subheader {
    font: 600 small Poppins;
    margin: calc((3rem - 1.5rem)/4) 16px;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 !important;
}

.mat-action-list .mat-subheader {
    font-family: inter;
  }

.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.datepicker {
    height: 2em;
}
.datepicker-container{
    background-color: $white-background;
}



.datepicker-container div.mat-form-field-infix {
    padding: 0em 0 !important;
}

.datepicker-container .mat-form-field-suffix {
    top: 0 !important;
}

.filter-container .datepicker {
    height: 1.6em;
}

.datepicker-container .mat-form-field-type-mat-date-range-input .mat-form-field-suffix {
    top: 0.2em !important;
}

.filter-container .mat-date-range-input-start-wrapper {
    position: inherit;
}

.filter-container .mat-date-range-input-start-wrapper .datepicker,
.filter-container .mat-date-range-input-end-wrapper .datepicker {
    height: 1.8em;
}

.filter-container .mat-date-range-input-start-wrapper .datepicker {
    margin-top: -0.2em;
}

.filter-container .mat-date-range-input-end-wrapper .datepicker,
.filter-container .mat-date-range-input-separator {
    position: relative;
    bottom: 0.2em;
}

.mat-calendar-table-header th {
    background: rgba(0, 0, 0, 0);
}

.mat-calendar-body-cell:focus {
    outline: none !important;
}

div.mat-mdc-form-field-infix {
    z-index: 100;
}
// remove Material's hover style
.mat-form-field-appearance-outline:not(.mat-form-field-outline-thick) .mat-form-field-flex:hover .mat-form-field-outline,
.ng-invalid .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    opacity: 1 !important;
}
.mat-form-field-appearance-outline:not(.mat-form-field-disabled):not(.mat-focused):not(.mat-form-field-invalid) .mat-form-field-flex:hover .mat-form-field-outline-thick {
    opacity: 0 !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: #495057;
    background-color: #fff;
    border-color: rgba(0, 0, 0, .15);
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px;
    border-color: rgba(0, 0, 0, .15);
}

.cdk-overlay-container {
    z-index: 1099 !important;
}

.mat-form-field.mat-warn .mat-input-element, .mat-form-field-invalid .mat-input-element {
    caret-color: rgb(74, 74, 74);
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick,
.has-danger .mat-form-field-appearance-outline.ng-invalid .mat-form-field-outline-thick {
    background: $error-background;
    border-color: $error-border;
}
