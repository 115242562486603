@import 'sms-colors';

.btn {
  line-height: 1.25;
  padding: .375rem 1rem;
}

.btn + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem;
}

button {
  margin: 1px;
}

button:focus {
  text-decoration: underline;
  outline: none;
}

a,
button,
.btn-link {
  color: $links-blue;
  cursor: pointer;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: $links-blue;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: $sms-blue;
}

.btn.btn-link:hover {
  text-decoration: none;
  color: $hover-blue;
}

.btn-primary {
  color: $btn-primary-color;
  background-color: $btn-primary-background-color;
  border-color: $btn-primary-border-color;
  font-weight: 600;
  transition: all 0.2s linear;
}

.btn-primary:hover {
  color: $btn-primary-hover-color;
  background-color: $btn-primary-hover-background-color;
  border-color: $btn-primary-hover-border-color;
}

.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: $btn-primary-hover-color;
  background-color: $btn-primary-hover-background-color;
  background-image: none;
  border-color: $btn-primary-hover-border-color;
}

.btn-secondary {
  color: $btn-secondary-color;
  background-color: $btn-secondary-background-color;
  border-color: $btn-secondary-border-color;
}

.btn-secondary:hover {
  color: $btn-secondary-hover-color;
  background-color: $btn-secondary-hover-background-color;
  border-color: $btn-secondary-hover-border-color;
}

.btn-secondary.has-danger {
  color: $sms-black;
  background: $error-background;
  border-color: $error-border;
}

.more {
  margin-bottom: 10px;
}

.btn-group-lg > .btn, .btn-lg {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: .3rem;
}

.btn[disabled], .btn.disabled {
  background-color: $btn-disabled-background-color;
  border-color: $btn-disabled-border-color;
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem;
}

.edit-actions button {
  margin-left: 20px;
}

.btn-margin {
  margin-right: 7px;
}