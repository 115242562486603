@import 'sms-colors';
/* dropdowns/popout menus */

/*needs header added, removed for style guide*/

.dropdown-menu-right {
  right: -10px;
}

/*needs header added, removed for style guide*/

.dropdown-menu {
  z-index: 2000;
}

.dropdown-button .dropdown-menu:before {
  content: "";
  position: relative;
  border: none;
}

.dropdown-button .dropdown-menu:after {
  content: "";
  position: relative;
  border: none;
}

.dropdown-item {
  white-space: nowrap;
  font-size: .875em;
  padding: .25rem 1.5rem;
  position: relative;
  color: $sms-black;
}

.dropdown-item .badge {
  position: absolute;
  right: 10px;
  top: -2px;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0;
  vertical-align: middle;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-left: .3em solid transparent;
}

.dropdown-item:hover,
table a.dropdown-item:not([href]):not([tabindex]),
table a.dropdown-item:not([href]):not([tabindex]):hover {
  color: $sms-black;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active,
table a.dropdown-item:active:not([href]):not([tabindex]) {
  color: #fff;
  text-decoration: none;
  background-color: #0275d8;
}

/* end dropdowns/popout menus */