@import 'sms-colors';
@import 'mixins';

.table {
  font-size: .875rem;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 0px;
  float: left;
  width: 100%;
}

.table-responsive {
  -ms-overflow-style: auto;
  width:100%;
}
thead {
  font-size: .875rem;
  font-weight: 600;
  @include mq('small') {
    font-size: .75rem;
  }
}

.table-responsive thead {
  background: $thead-background-color;
}

.file-type {
  font-size: 1.125rem;
  vertical-align: text-top;
}

.file-size {
  font-size: .688rem;
  display: block;
}

.file-size-inline {
  font-size: .688rem;
  display: inline;
}

.upper {
  text-transform: uppercase;
}

.table thead th {
  padding: 5px 10px !important;
  background: #dce2e9;
}
table tbody tr td {
  line-height: 1.5;
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid $light-border-background;
  white-space: nowrap;
}

.table td,
table th:first-of-type {
  white-space: nowrap;
}

.wrapwhitespace {
  white-space: normal !important;
}

td {
  vertical-align: middle !important;
}

table h4 {
  font-size: .750rem;
  font-weight: 100;
  margin: 0;
  color: $black-text;
}

table a {
  color: $sms-blue;
  display: inline-block;
  cursor: pointer;
}

article table a {
  color: $links-blue;
  display: pointer;
}

table p {
  font-weight: 500;
  margin-bottom: 0;
  max-width: 100%;
  white-space: normal;
}

table .title {
  font-weight: 600;
}

table a:hover {
  color: $table-links-color;
  text-decoration: none;
}

.ellipsis-action-item  a:hover {
  color: $sms-black !important;
}

table a:not([href]):not([tabindex]) {
  color: $sms-blue;
}

table a:not([href]):not([tabindex]):hover,
table a:not([href]):not([tabindex]):focus {
  color: $table-links-color;
}

table .dropdown-menu-right {
  right: -13px;
  top: 20px;
}

table tr.table-tr-even {
  background: #F1F4F6;
  border-top: 1px solid #F1F4F6
}

table .table-tr-odd {
  background: white;
  border-top: 1px solid white
}

.actions {
  margin: 0;
  padding: 0;
  font-size: .875rem;
  font-weight: 100;
}

.actions li {
  display: inline;
  text-align: center;
}

.actions li:before, .actions li span:before, .actions span#ellipsis:before {
  content: "|";
  padding: 0 5px;
}

.actions li:first-child:before, .actions .ellipsis-action-item span:before {
  content: none;
}

.actions li:first-child:before, .actions .first-action-item span:before {
  content: none;
}

.actions i {
  font-size: 1.125rem;
  position: relative;
  top: 2px;
}

.actions.inner-table {
  margin-left: 5px;
  display: inline-block;
}

.ellipsis-action-item {
  padding-left: 20px;
}

a.truncate {
  font-weight: 600;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown {
  position: relative;
}

.sms-col-hidden {
  display: none;
}

.sms-col-show {
  display: table-cell;
}

.multi-table-heading {
  position: absolute;
}

.past-due-red {
  color: $past-due-red;
}
.bold {
  font-weight: 600;
}

th {
  background: #dce2e9;
}

.table-striped tbody tr:nth-of-type(odd) td {
  background: $td-light-blue-background !important;
}

.table-striped tbody tr:nth-of-type(even) td {
  background: $white-background;
}
