@import 'sms-colors';

.filter {
  padding-bottom: 0;
  position: relative;

  button {
    font-size: .875rem;
  }
}

.collapse-filter {
  margin-top: 20px;
}

.filter-apply {
  margin: 20px 0;
}

.add-filter i {
  font-size: .875rem;
}

.filter-by-title {
  border-radius: 0;
  border: 1px solid rgba(127, 143, 164, 0.3);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.filter-nav .nav {
  display: block;
  width: 100%;
  white-space: nowrap;
}

.filter-nav .nav li {
  display: inline-flex;
}

.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
  color: $sms-dark-blue;
  cursor: default;
  background-color: transparent;
  border-bottom: 3px solid $nav-pills-border-bottom;
  border-radius: 0;
}

.filter-nav a {
  font-weight: 600;
  color: $sms-dark-blue;
  font-size: 1rem;
}

#collapse-filter {
  margin-top: 20px;
}

#collapse-filter .form-control {
  margin-right: 15px;
  max-width: 260px;
}

#collapse-filter .add-filter {
  min-width: 13px;
}

.filter-apply {
  margin: 20px 0;
}

.remove-filter,
.add-filter i {
  font-size: .875rem;
}

.tab-pane {
  padding-bottom: 20px;
}

.tab-content {
  position: relative;
  margin-bottom: 50px;
}

.large-filter-mobile {
  display: none;
}

.results {
  padding-top: 10px;
  font-size: .875rem;
  font-style: italic;
  color: $gray-text;
  text-indent: 10px;
}

@media screen and (max-width: 768px) {
  #collapse-filter .form-group {
    position: relative;
    margin-bottom: 1rem;
  }

  #collapse-filter .form-control {
    margin-right: 0;
    max-width: calc(100% - 24px);
    margin-bottom: 1rem;
  }

  #collapse-filter .add-filter {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
