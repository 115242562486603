/* Title Block */
.breadcrumb {
  margin-left: 15px;
}

.topWrapper {
    margin: 24px 0 0;
  }

  h1,
  .h1 {
    margin-top: 24px;
  }

  .extraSmall {
    .topWrapper {
      flex-direction: column;
      width: auto;
      margin-top: 12px;
    }

    h1,
    .h1 {
      margin-top: 12px;
    }
  }

  a:hover {
    color: #014c8c;
    text-decoration: none;
    outline: none !important;
  }

  .topWrapper .titleBlock h1,
  .topWrapper .titleBlock .h1,
  h1.modal-title {
    padding-right: 24px;
    margin-top: 0;
  }

  .extraSmall {
    .topWrapper .titleBlock h1,
    .topWrapper .titleBlock .h1,
    h1.modal-title {
      padding-right: 0;
    }
  }

  .titleBlock+h3,
  h1+h3 {
    margin-top: 18px;
    clear: left;
  }

  h1+h2 {
    margin-bottom: 18px;
  }

  h1 .name {
    font-weight: 400;
    margin-left: 10px;
  }

  h1 .name:before {
    content: '(';
  }

  h1 .name:after {
    content: ')';
  }

  h1 + .note {
    border-top: 1px solid #979797;
    padding-top: 10px;
    margin: 12px 0 18px;
    color: #000;
    font-size: .875rem;
  }

  h1 + .note .descriptor {
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 4px;
    font-size: .875rem;
  }

  // h1 + .note .description {
  // }

  .topWrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .pageNav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 0 0 30px;
  }

  .showMenu {
    .topWrapper {
      flex-direction: column;
    }

    .pageNav {
      width: 100%;
      margin: 20px 0;
      align-items: stretch;
    }
  }

  .page-edit-nav {
    margin: 0 0 12px;
  }

  /* Data Display */
  .dataBlock {
    margin: 0 0 1rem;
  }

  .dataBlock .descriptor {
    font-weight: 600;
    font-size: .75rem;
    margin: 1.5rem 0 .25rem;
  }

  .dataBlock .data {
    font-size: .875rem;
  }

  .notPopulated {
    font-style: italic;
    color: #999;
    text-transform: lowercase;
  }

  .notPopulated:before {
    content: '(';
  }

  .notPopulated:after {
    content: ')';
  }

  /* END Data Display */

  .extraSmall {
    form {
      margin-right: 0;
    }
  }
  /* END Title Block */

  /* Tablesaw collumn toggle needs to be moved down when button included. */
  .btnTableSelectAll+.tablesaw-bar .tablesaw-columntoggle-btnwrap.visible .tablesaw-columntoggle-popup {
    top: 52px;
  }

  .selectedCountBlock {
    font-size: .875rem;
    font-style: italic;
    color: #4a4a4a;
    margin-left: 18px;
  }

  .pageActionsMenu {
    clear: left;
  }

  #pageActionsMenuIcon {
    display: none;
    width: 28px;
    height: 30px;
    margin: 0;
    position: absolute;
    top: -1px;
    right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    padding: 5px;
    border-radius: 3px 2px 0 0;
  }

  #pageActionsMenuIcon.open {
    background-color: #F4F5F7;
  }

  #pageActionsMenuIcon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 18px;
    background: #2F415A;
    border-radius: 1px;
    opacity: 1;
    left: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }

  #pageActionsMenuIcon span:nth-child(1) {
    top: 7px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #pageActionsMenuIcon.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 7px;
    left: 7px;
  }

  #pageActionsMenuIcon span:nth-child(2) {
    top: 14px;
    left: 5px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #pageActionsMenuIcon.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }

  #pageActionsMenuIcon span:nth-child(3) {
    top: 21px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #pageActionsMenuIcon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 20px;
    left: 7px;
  }

  .pageActionsMenu .btn-sm {
    padding: 4px 8px;
  }

  .showMenu {
    #pageActionsMenuIcon {
      display: block;
    }

    .pageActionsMenu {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 18px;
      background-color: #F4F5F7;
      margin-top: -6px;
      margin-bottom: 18px;
    }

    .pageActionsMenu.active {
      display: flex !important;
    }

    .pageActionsMenu .btn-sm {
      padding: 8px;
      white-space: unset;
      text-align: left;
    }

    #pageActionsMenuIcon {
      display: block;
    }
  }

  .pageActionsMenu .fas {
    margin-right: 5px;
  }

  button .fa,
  button .fas,
  button .faf,
  button .fal,
  button .fad,
  .btn .fa,
  .btn .fas,
  .btn .faf,
  .btn .fal,
  .btn .fad {
    margin-right: 5px;
  }

  /* Page Nav Page Actions Menu */
  .pageNav .btn {
    margin-bottom: 8px;
  }
  .pageNav .pageActionsMenu {
    align-items: stretch;
    background-color: #F4F5F7;
    padding: 0;
    width: 224px;
    margin: 12px 0;
  }

  .showMenu {
    .pageNav .pageActionsMenu {
      display: flex;
      width: 100%;
    }

    .grid.pageActionsMenuOpen {
      margin-top: 0 !important;
    }
  }

  .pageNav .pageActionsMenu .menuTitle {
    text-align: center;
    background-color: #DCE2E9;
    padding: 8px;
    font-size: 14px;
    position: relative;
  }

  .pageNav .pageActionsMenu .menuTitle:hover {
    background-color: #DFE7EF;
    cursor: pointer;
  }

  .pageNav .pageActionsMenu .menuTitle:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 6px;
   // background: url("images/caret-up-solid.svg") 0 / contain no-repeat;
    margin: 0 0 2px 6px;
    filter: invert(43%) sepia(8%) saturate(10%) hue-rotate(82deg) brightness(99%) contrast(93%);
  }

  // .pageNav .pageActionsMenu.closed .menuTitle:after {
  //   background-image: url("images/caret-down-solid.svg");
  // }

  .pageNav .pageActionsMenu h1 {
    font-weight: normal;
    font-size: 14px;
  }

  .pageNav .pageActionsMenu .menuItems {
    padding: 16px 0 18px;
  }

  .pageNav .pageActionsMenu .menuItem {
    line-height: 18px;
    padding: 6px 21px 6px 24px;
    font-size: 14px;
    display: block;
  }

  .pageNav .pageActionsMenu .menuItem:hover {
  background-color: #DFE7EF;
  }

  .pageNav .pageActionsMenu .headingOnly {
    display: block;
    padding: 0 21px 2px 24px;
    margin-bottom: 2px;
  }

  .pageActionsMenu .menuItems, .pageActionsMenu .menuItems .subMenu {
    display: flex;
    flex-direction: column;
  }

  .pageNav .pageActionsMenu .subMenu .menuItem {
    line-height: 17px;
    padding: 6px 21px 7px 45px;
    font-size: 13px;
    display: inline-block;
    text-indent: -21px;
  }

  .pageNav .pageActionsMenu .menuItem.word:before,
  .pageNav .pageActionsMenu .menuItem.pdf:before,
  .pageNav .pageActionsMenu .menuItem.csv:before,
  .pageNav .pageActionsMenu .menuItem.excel:before,
  .pageNav .pageActionsMenu .menuItem.ppt:before,
  .pageNav .pageActionsMenu .menuItem.image:before,
  .pageNav .pageActionsMenu .menuItem.audio:before,
  .pageNav .pageActionsMenu .menuItem.video:before,
  .pageNav .pageActionsMenu .menuItem.settings:before {
    content: '\f1c2';
    font-weight: 600;
    font-size: 14px;
    line-height: 1.12;
    font-family: FontAwesome;
    color: #2F415A;
    margin: 0 6px 0 4px;
  }

  .pageNav .pageActionsMenu .menuItem.pdf:before {
    content: '\f1c1';
  }

  .pageNav .pageActionsMenu .menuItem.csv:before {
    content: '\f6dd';
  }

  .pageNav .pageActionsMenu .menuItem.excel:before {
    content: '\f1c3';
  }

  .pageNav .pageActionsMenu .menuItem.ppt:before {
    content: '\f1c4';
  }

  .pageNav .pageActionsMenu .menuItem.image:before {
    content: '\f1c5';
  }

  .pageNav .pageActionsMenu .menuItem.audio:before {
    content: '\f1c7';
  }

  .pageNav .pageActionsMenu .menuItem.video:before {
    content: '\f1c8';
  }

  .pageNav .pageActionsMenu .menuItem.settings:before {
    content: '\f013';
    margin-left: 0;
  }

  .pageNav .pageActionsMenu .menuItem.word:hover:before,
  .pageNav .pageActionsMenu .menuItem.pdf:hover:before,
  .pageNav .pageActionsMenu .menuItem.csv:hover:before,
  .pageNav .pageActionsMenu .menuItem.excel:hover:before,
  .pageNav .pageActionsMenu .menuItem.ppt:hover:before,
  .pageNav .pageActionsMenu .menuItem.image:hover:before,
  .pageNav .pageActionsMenu .menuItem.audio:hover:before,
  .pageNav .pageActionsMenu .menuItem.video:hover:before,
  .pageNav .pageActionsMenu .menuItem.settings:hover:before {
    color: #3b577d;
  }

  .menuItem.leavePage:after {
    content: '\f061';
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    font-family: FontAwesome;
    color: #707070;
    margin-left: 6px;
  }

  /* END Page Nav Page Actions Menu */