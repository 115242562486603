@import 'sms-colors';

.field-error {
  border-color: $error-border;
  background-color: $error-background;
}

.invalid-text {
  color: $error-text;
  font-size: 14px;
  max-width: 445px;
  position: absolute;
  text-align: right;
  float: right;
}

.invalid-text::first-letter {
  text-transform: capitalize
}
