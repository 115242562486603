/* Brand Colors */

$jjk-red: #8E213F;
$jjk-dark-red: #6F162F;

$sms-black: #292b2c;
$sms-dark-blue: #242832;
$sms-blue: #2F415A;
$p-color: #2F415A;
$sub-headings-gray: #747F85;
$sms-accent: #BCC5C9;
$links-blue: #347AB7;
$links-hover: #014c8c;
$cta-green: #5fBB46;
$chemical-blue: #3981C4;
$incident-orange: #F19122;

$cta-dark-green: #3C8528;
$cts-disabled: #6c757d;

$hr-accent: #4f5c7a;
$light-blue-accent: #dce2e9;
$black-text: #000;
$white-accent: #fff;

/* Layout Colors */

$light-gray-background-color: #dedede;
$light-gray-border-color: #dedede;
$status-gray: $light-gray-background-color;

$header-background-color: #f4f4f4;
$header-left-background-color: $light-gray-background-color;

$mobile-nav-background-color: #eee;

$sidenav-header-color: #BABABA;
$sidenav-text-color: #B7BEBE;
$sidenav-text-active-color: $white-accent;
$sidenav-background-color: $sms-blue;
$sidenav-background-active-color: $sms-dark-blue;
$sidenav-divider-color: $hr-accent;

$footer-top-background-color: #999999;
$footer-background-color: $header-left-background-color;
$breadcrumb-item-active: #636c72;

/* Filter and Nav Pills*/

$nav-pills-border-bottom: $cta-green;

/* Page Colors */

$h1-color: $black-text;
$h234-color: $black-text;
$hr-color:$hr-accent;
$card-text: #2E4059;

$alert-warning-background: #fcf8e3;

$alert-warning-light-yellow: #f1ef97;
$alert-warning-dark-green: #2e734b;

$italics-text: #999;

$gray-text: #4a4a4a;

$box-shadow-color: #000;

$group-separator: #979797;

$alertSubText: #721c24;

/* Table Colors */

$thead-background-color: $light-blue-accent;
$td-light-blue-background: #f4f5f7;
$light-border-background: #eceeef;
$medium-gray-border: #808080;
$table-links-color: $links-blue;
$past-due-red: #a94442;

/* Form Colors */

$error-text: #dc3545;
$error-border: #dc3545;
$error-background: #f8d7da;
$success-border: #28a745;
$success-background: #DFF0D8;
$hover-blue: #18548f;
$disabled-color: #808080;
$disabled-background-color: #F2F2F2;
$white-background: #fff;
$background-hover: #f7f7f9;
$input-background-color: #ccc;
$input-disabled-background-color: #eee;

$note-color: #636c72;

$select-color: #808080;
$select-background-color: $input-background-color;
$select-border-color: #999;

$btn-primary-color: $white-accent;
$btn-primary-background-color: $cta-green;
$btn-primary-border-color: $cta-green;
$btn-primary-hover-color: $white-accent;
$btn-primary-hover-background-color: $cta-dark-green;
$btn-primary-hover-border-color: $cta-dark-green;

$btn-secondary-color: $sms-black;
$btn-secondary-background-color: $white-background;
$btn-secondary-border-color: #ccc;
$btn-secondary-hover-color: $sms-black;
$btn-secondary-hover-background-color: #e6e6e6;
$btn-secondary-hover-border-color: #adadad;

$btn-disabled-background-color: $cts-disabled;
$btn-disabled-border-color: $cts-disabled;
$btn-icon-background-color: #707070;
$btn-icon-background-hover-color: #878787;
$split-form-background: #F8F9FA;

/* Social Links Colors */

$facebook-color: #006FB7;
$twitter-color: #5EA9D6;
$linkedin-color: #0077B4;
$google-color: #DB4937;
$youtube-color: #E32C28;

/* Hazard Colors */

$red-color: #dc3545;
$yellow-color: #FFC107;
$blue-color: #007bff;
$green-color: #28a745;
$cyan-color: #17a2b8;
$white-color: #fff;
$border-on-white: #ccc;
$dark-text-color: #000;
