@import 'mixins';
@import 'sms-colors';

*,
button,
input,
optgroup,
select,
textarea {
  font-family: 'inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: .04em;
}

body {
  font-size: .938rem;
  -webkit-font-smoothing: antialiased;
}

b,
strong {
  font-weight: 600;
}

a {
  color: $links-blue;
  text-decoration: none;
}

a:hover {
  color: $links-hover;
  text-decoration: none;
}

.hide {
  display: none !important;
}

.invisible {
    visibility: hidden;
}

.wrapper {
  margin-left: $sidenav-width;
  padding-top: $content-margin-top-large;
  min-height: calc(100vh - #{$header-height} - #{$content-margin-top-large});

  @include mq('medium') {
    margin: $content-margin-top-medium 0 0 0;
  }

  @include mq('smallest') {
    margin: $content-margin-top-small 0 0 0;
  }
}

.main-content {
  margin-top: $content-margin-top-large - 70px;
  padding: 0 30px;

  @include mq('medium') {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 75px;
  }

  @include mq('smallest') {
    margin-top: 65px;
  }
}

section {
  padding: 10px 0;
}

.description {
  max-width: 960px;
}

input.input-with-button {
  border-radius: 0.25rem 0 0 0.25rem;
  float: left;
  width: 86%;
}

.icon-button {
  background-color: $btn-icon-background-color;
  border-radius: 0 0.25rem 0.25rem 0;
  border: none;
  color: $white-accent;
  height: 38px;
  margin: 0;
  padding: 0.25rem 0.5rem;
  width: 38px;
}

.icon-button:hover {
  background-color: $btn-icon-background-hover-color;
}

.description p {
  margin: 0;
  padding: 0;
}

h1 {
  font-weight: 600;
  font-size: 1.5em;
  color: $h1-color;
}

h2 {
  font-size: 1.125rem;
  font-weight: 600;
  color: $h234-color;
}

h3 {
  font-size: .750rem;
  font-weight: 600;
  text-transform: uppercase;
  color: $h234-color;
}

h4 {
  font-size: .750rem;
  font-weight: 100;
  margin: 0;
  color: $h234-color;
}

p {
  color: $p-color;
  line-height: 1.8;
  margin-bottom: 10px;
  font-size: .875rem;
}

ul {
  color: $p-color;
  line-height: 1.8;
  margin-bottom: 10px;
  font-size: .875rem;
}

ol {
  color: $p-color;
  line-height: 1.8;
  margin-bottom: 10px;
  font-size: .875rem;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 1rem;
  margin-bottom: 1rem;
  box-sizing: content-box;
  overflow: visible;
  opacity: 1;
}

i {
  font-style: normal;
}

.results {
  padding-top: 10px;
  font-size: .875rem;
  font-style: italic;
  color: $gray-text;
  text-indent: 10px;
}

.tab-pane {
  padding-bottom: 20px;
}

.italic {
  font-style: italic;
  color: $italics-text;
  letter-spacing: normal;
}

.breadcrumb {
  font-size: .75rem;
  padding: 0rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: $white-accent;
  border-radius: .25rem;
}

.breadcrumb-item.active {
  color: $breadcrumb-item-active;
}

.breadcrumb-item {
  float: left;
}

.modal {
  width: 100vw;
}

.modal.fade {
  z-index: 1065;
}

.modal-header,
.modal-footer {
  border: none;
}

.modal-title {
  @include mq('smallest') {
    font-size: 1rem;
  }
}

.empty-state button,
.empty-state button:hover {
  background: none;
}

.empty-state {
  border: 1px dashed #ccc;
  max-width: 500px;
  padding: 50px;
}

.empty-state p {
  color: #6E6E6E;
  padding: 20px 0;
}

.naics {
  .invalid-text {
    color: $error-text;
    font-size: 14px;
    position: relative;
    text-align: right;
  }

  .form-group {
    max-width: 500px;
  }

  .field-error {
    border-color: $error-border;
    background-color: $error-background;
  }
}


.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Loading spinner */

.loading {
  float: left;
}

.loadingText {
  float: left;
  margin-left: 10px;
  padding-top: 7px;
}

.loader {
  float: left;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  border-bottom: 6px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End Loading spinner */

/* Editorial Content */

article table {
  width: 100%;
  color: $p-color;
  line-height: 1.8;
  font-size: .875rem;
}

article table tbody tr td {
  vertical-align: top !important;
  padding: 3px;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0;
  color: $p-color;
  text-align: left;
  caption-side: top;
}

/* End Editorial Content */

.file-hint {
  font-size: .6rem !important;
  color: #636c72;
  line-height: 1.1;
  margin: 3px 0 0 4px;
}

.clear {
  clear: both;
}

div.popover.show {
  z-index: 1065;
}

.link-button {
  background: none;
  border: none;
  color: $links-blue;
  display: block;
  font-size: .875rem;
  margin: 8px 0;
}

.link-button:hover {
  color: $links-hover;
}

.link-button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
  text-decoration: none;
}

.note {
  font-size: .6rem;
  color: $note-color;
  line-height: 1.1;
  margin: 3px 0 0 4px;
}

.message-block {
  font-size: .85rem;
  line-height: 1.2;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.info {
  @include notification-gradient($cyan-color);
}

.danger {
  @include notification-gradient($red-color);
}

.success {
  @include notification-gradient($green-color);
}

.warning {
  @include notification-gradient($yellow-color);
}