.print-logo {
  display: none !important;
}

.print-copyright {
  display: none !important;
}

@media print {

  /* global */
  *,
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: 'inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  .print-hide {
    display: none !important;
  }

  .site-logo {
    float: right;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  body {
    font-size: 100%;
    -webkit-font-smoothing: antialiased;
  }

  .row {
    display: block !important;
  }

  h1 {
    font-weight: 600;
    font-size: 1.5em;
    color: #000;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #7f8fa4;
  }

  h3 {
    font-size: .750rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #7f8fa4;
  }

  h4 {
    font-size: .750rem;
    font-weight: 100;
    margin: 0;
    color: #7f8fa4;
  }

  p {
    color: #354052;
    line-height: 2;
  }

  form label {
    font-weight: 600;
  }

  .no-padding {
    padding: 0;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(79, 92, 122, 1);
  }

  i {
    font-style: normal;
  }

  a[href^="tel:"] {
    color: #000;
    text-decoration: none;
  }

  a:not([href]) {
    color: #4a4a4a;
  }

  a:not([href]):hover, a:not([href]):focus {
    text-decoration: none;
  }

  /*.btn:focus {
        box-shadow: none;
    }*/
  button {
    cursor: pointer;
  }

  div.print-logo {
    display: block !important;
    margin-bottom: 20px;
    margin-left: 0.75cm;
    margin-right: 0.75cm;
    &.report-view {
      display: none;
    }
  }

  @page {
    size: auto;
    margin: 1.5cm 1cm;
  }

  div.container-fluid div.main-content div.description {
    display: block;
    &.report-view {
      display: none;
    }
  }

  div.print-copyright {
    display: inline-block !important;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    margin-bottom: 20px;
    margin-left: 0.75cm;
    margin-right: 0.75cm;
    &.report-view {
      display: none;
    }
  }

    div.print-copyright p {
    font-size: 1.300rem;
    margin: 0;
  }

  .img-fluid {
    height: auto;
    max-width: 100%;
  }

  /* end global */
  .wrapper {
    margin: 0 !important;
  }

  .main-content {
    margin: 0 !important;
  }

  header {
    display: none;
  }

  .search {
    display: none;
  }

  button {
    display: none !important;
  }

  .sidebar {
    display: none;
  }

  .breadcrumb {
    display: none;
  }

  .alerts {
    display: none;
  }

  .filter-nav {
    display: none;
  }

  .jumbotron {
    display: none;
  }

  .expand-collapse {
    display: none;
  }

  .large-filter-mobile {
    display: none !important;
  }

  .navbar-form {
    display: none;
  }

  .sortable-btn {
    display: block !important;
  }

  .table-responsive {
    display: block;
    width: 100%;
    overflow: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .actions {
    display: none;
  }

  footer {
    display: none;
  }

  a[href] {
    content: none !important;
    text-decoration: none;
  }
}
