@use "../node_modules/ag-grid-community/styles" as ag;

// START BOOTSTRAP OVERRIDES
$table-striped-bg-factor: .01;
// END BOOTSTRAP OVERRIDES

// 3rd party SASS files should be imported here. 3rd party CSS files can be imported under "styles" in angular-cli.json.
@import 'thirdparty';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/@ng-select/ng-select/themes/default.theme.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Local SASS files
@import 'sizes';
@import 'sms-colors';
@import 'tables';
@import 'dropdown';
@import 'filters';
@import 'buttons';
@import 'checkbox';
@import 'sms-main';
@import 'print';
@import 'textbox';
@import 'forms';
@import 'cards';
@import 'charts';
@import 'pagenav';
@import 'material-overrides';

html {
    scroll-behavior: auto !important;
}

.date-required-validation {
  max-width: 220px !important;
  font-style: normal;
}

.apptree>ul:first-child {
  padding: 0px !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
app-root > div {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .wrapper {
    flex: 1 1 auto;
  }
}

.actions-button-cell {
  overflow: visible !important;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

@include ag.grid-styles((
  // ^^^ Include ag.grid-styles which it is no longer nested  in .ag-theme-{name} {}
  theme: alpine,
  // ^^^ Add the theme name to the mixin
  //alpine-active-color: red,
  // ^^^ Pass the same parameters. The new API validates parameters so you will be
  //     notified if any of the names or values you pass are not valid for the new API
  icon-font-family: FontAwesome,
  header-foreground-color: $header-background-color,
  header-background-color: $sms-blue,
  background-color: $white-background,
  odd-row-background-color: rgba(0, 0, 0, 0.05),
  control-panel-background-color: $white-background,
  range-selection-border-color: #242832,
  range-selection-background-color: rgba(#007BFF, 0.1),

  --ag-icon-font-family: "FontAwesome",
  //"range-selection-highlight-color": ag-derived(range-selection-background-color, $opacity: 0.6),
  //icons-data: null, // prevent default font from being embedded
  // define icon map - not required when changing between two
  // provided theme fonts, see next code sample for more details
  --ag-icon-font-code-aggregation: "\f247",
  --ag-icon-font-code-arrows: "\f0b2",
  --ag-icon-font-code-asc: "\f062",
  --ag-icon-font-code-cancel: "\f057",
  --ag-icon-font-code-chart: "\f080",
  --ag-icon-font-code-color-picker: "\f576",
  --ag-icon-font-code-columns: "\f0db",
  --ag-icon-font-code-contracted: "\f146",
  --ag-icon-font-code-copy: "\f0c5",
  --ag-icon-font-code-cross: "\f00d",
  --ag-icon-font-code-desc: "\f063",
  --ag-icon-font-code-expanded: "\f0fe",
  --ag-icon-font-code-eye-slash: "\f070",
  --ag-icon-font-code-eye: "\f06e",
  --ag-icon-font-code-filter: "\f0b0",
  --ag-icon-font-code-first: "\f100",
  --ag-icon-font-code-grip: "\f58d",
  --ag-icon-font-code-group: "\f03a",
  --ag-icon-font-code-last: "\f101",
  --ag-icon-font-code-left: "\f060",
  --ag-icon-font-code-linked: "\f0c1",
  --ag-icon-font-code-loading: "\f110",
  --ag-icon-font-code-maximize: "\f2d0",
  --ag-icon-font-code-menu: "\f0c9",
  --ag-icon-font-code-minimize: "\f2d1",
  --ag-icon-font-code-next: "\f105",
  --ag-icon-font-code-none: "\f338",
  --ag-icon-font-code-not-allowed: "\f05e",
  --ag-icon-font-code-paste: "\f0ea",
  --ag-icon-font-code-pin: "\f276",
  --ag-icon-font-code-pivot: "\f074",
  --ag-icon-font-code-previous: "\f104",
  --ag-icon-font-code-right: "\f061",
  --ag-icon-font-code-save: "\f0c7",
  --ag-icon-font-code-small-down: "\f107",
  --ag-icon-font-code-small-left: "\f104",
  --ag-icon-font-code-small-right: "\f105",
  --ag-icon-font-code-small-up: "\f106",
  --ag-icon-font-code-tick: "\f00c",
  --ag-icon-font-code-tree-closed: "\f105",
  --ag-icon-font-code-tree-indeterminate: "\f068",
  --ag-icon-font-code-tree-open: "\f107",
  --ag-icon-font-code-unlinked: "\f127",

  //Column resize
  --ag-header-column-resize-handle-color: $secondary,
  --ag-header-column-resize-handle-width: 1px,

  //Filter input disabled
  --ag-input-disabled-background-color: #f1f2f4,

));


.ag-theme-alpine {
  --ag-range-selection-highlight-color: ag-derived(range-selection-background-color, $opacity: 0.6),
}

.ag-column-drop-horizontal {
  background-color: $white-background;
}

.ag-icon {
    // required because Font Awesome uses bold for its icons
    font-weight: bold;
    color: $sms-blue;
}
.ag-theme-alpine .ag-root-wrapper-body
.ag-header .ag-icon {
  color: $white-background;
}
// filter toolpanel search input Color was inheriting from the header-foreground-color set in the theme above, which is basically white
.ag-theme-alpine .ag-filter-toolpanel-search {
  color: black;
}
.ag-theme-alpine .ag-root-wrapper-body .ag-checkbox-input-wrapper {
  background: none;
}
.ag-theme-alpine .ag-root-wrapper-body .ag-checkbox-input-wrapper::after {
  content: url(/assets/images/unchecked.svg);
}
.ag-theme-alpine
  .ag-root-wrapper-body
  .ag-checkbox-input-wrapper.ag-checked::after {
    content: url(/assets/images/checked.svg);
}
.ag-theme-alpine
  .ag-root-wrapper-body
  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    font-family: FontAwesome;
    font-weight: bold;
    content: "\f068";
}
.ag-theme-alpine .ag-panel-title-bar-button .ag-icon-cross,.ag-icon-maximize,.ag-icon-minimize {
  color: $white-background;
}
.ag-theme-alpine .ag-root-wrapper-body
.ag-filter-toolpanel-group-title {
  color: $sms-dark-blue;
  font-weight: normal;
}
.ag-theme-alpine .ag-root-wrapper-body
.ag-side-button {
  font-weight: bold;
  color: $sms-blue;
}

.ag-theme-alpine .ag-side-button-button:hover {
  color: #347AB7;
}

.ag-theme-alpine .ag-tab-selected {
  border-bottom-color: #2F415A;
}

.ag-theme-alpine .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
  border-left-color: #2F415A;
}

.ag-theme-alpine .ag-ltr input[class^=ag-][type=text] {
  color: $sms-blue;
}

.ag-theme-alpine .ag-ltr input[class^=ag-][type=date] {
  color: $sms-blue;
}

.ag-theme-alpine .ag-root-wrapper-body .ag-header .ag-icon {
  color: #fff;
  padding: 3px;
}

.ag-theme-alpine .ag-icon {
    font-family: FontAwesome;
}

.ag-theme-alpine .ag-icon-chart::before {
  content: url(/assets/images/actions.png);
}

.ag-theme-alpine .ag-cell-wrapper {
  align-items: initial;
}

.ag-theme-alpine .ag-icon-chart::before {
  content: url(/assets/images/actions.png);
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
  content: url(/assets/images/checked.svg);
}

.ag-theme-alpine .columns-active-icon::after {
  content: url(/assets/images/columns-active-icon.svg);
}

.ag-theme-alpine .columns-inactive-icon::after {
  content: url(/assets/images/column-inactive-icon.svg);
}

.ag-theme-alpine .actions-inactive-icon::after {
  content: url(/assets/images/actions-inactive-icon.svg);
}

.ag-theme-alpine .ag-checkbox-input-wrapper::after {
  content: url(/assets/images/unchecked.svg);
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
  box-shadow: none !important;
}

.ag-theme-alpine .ag-icon-grip::before {
  content: url(/assets/images/grip-icon.svg);
}
.ag-side-button-label {
  writing-mode: vertical-lr;
}
.text-center {
  display: inline-block;
  justify-content: center;
}
.ag-theme-alpine {
  .ag-side-button-button:focus {
    text-decoration: none;
  }
  .ag-filter-body {
    .ng-select {
      border-style: none;
      height: ag-param(list-item-height);
      .ng-select-container {
        min-height: 24px;
      }
    }
    .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
      outline: none;
      border-color: ag-param(border-color);
      box-shadow: ag-param(input-focus-box-shadow);
    }
  }
  .ng-select.ng-select-single {
    .ng-select-container {
      border-radius: 3px;
      border-width: 1px;
      border-style: solid;
      border-color: ag-param(border-color);
      height: ag-param(list-item-height);
      &:focus {
        outline: none;
        box-shadow: ag-param(input-focus-box-shadow);
      }
      .ng-value-container {
        &, .ng-input {
        padding-left: ag-param(grid-size);
        }
      }
      .ng-arrow-wrapper {
        padding: 0;
        text-align: right;
        .ng-arrow {
          display: none;
        }
      }
      span.ng-arrow-wrapper::before {
        font-family: ag-param(icon-font-family);
        font-size: ag-param(icon-size);
        text-align: right;
        content: ag-icon-char(small-down);
      }
    }
    .ng-option {
      padding: ag-param(grid-size);
      height: ag-param(list-item-height);
    }
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: ag-param(row-hover-color);
  }
  .ag-tabs {
    overflow: visible;
  }
  input[class^='ag-'][type='time'] {
    min-height: 24px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: ag-param(border-color);
    &:focus {
      outline: none;
      box-shadow: ag-param(input-focus-box-shadow);
    }
  }
  .ag-header-tooltip{
    background-color: #ffffff;
    width: 350px;
    color: #999eab;
    border-radius: 4px;
    padding: 5px;
  }
  .fa-info-circle {
    color: #F4F4F4;
  }

  .fa-info-circle.info-popover {
    margin-left: 5px;
  }

/* callouts must have a parent that creates a new z-index stacking context so that the caret drop-shadow moves behind only it's parent */
.callout-container {
  position: relative;
  z-index: 0;
  background-color: #f6f9fd;
  padding: 0px;
}

.callout {
  position: relative;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  min-height: 1em;
}

.callout::before,
.callout::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 10px solid black;
  transform-origin: 0 0;
  transform: rotate(-45deg);
}

.callout::before {
  z-index: -1;
}

.caret-left::before,
.caret-left::after {
  margin-left: -14px;
  top: 1.7em;
  left: 0;
}

.caret-left::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-left::after {
  border-color: #fff transparent transparent #fff;
}


.caret-right::before,
.caret-right::after {
  top: 1.7em;
  right: 0;
  margin-right: -5px;
}

.caret-right::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-right::after {
  border-color: transparent #fff #fff transparent;
}

.caret-up::before,
.caret-up::after {
  left: 10%;
  top: 0;
}

.caret-up::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-up::after {
  border-color: #fff #fff transparent transparent;
}

.caret-down::before,
.caret-down::after {
  left: 50%;
  top: 100%;
}

.caret-down::before {
  box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.4);
}

.caret-down::after {
  border-color: transparent transparent #fff #fff;
}

.pdf-product-list-added {
  content: url(/assets/images/pdf-product-list-added.svg);
}

.custom-icon-start {
  margin-bottom: -5px;
  content: url(/assets/images/start.svg);
}

.custom-icon-remove {
  margin-bottom: -5px;
  content: url(/assets/images/remove.svg);
}
    .ag-cell.red-bold-text {
        color: red;
        font-weight: bold;
    }
}

.location-dot-solid {
  content: url(/assets/images/location-dot-sharp-solid.svg);
}

.buildings-sharp-solid {
  content: url(/assets/images/buildings-sharp-solid.svg);
}

ag-grid-settings-toolpanel {
  width: 100%;
}

.grid-with-settings-panel .ag-side-buttons {
  padding-top: 11px;

  > .ag-side-button:first-child > button:first-child  {
    min-height: 0;
    padding-bottom: 8px;
    padding-top: 12px;

    > .ag-side-button-icon-wrapper {
      margin-bottom: 0;

      > .ag-icon {
        font-size: 1.5em;
      }
    }
  }
}

.ag-theme-alpine .ag-row-loading .ag-loading {
  padding-top: 15px;
  align-items: start;
}

.fa-spinner {
  color: $sms-blue;
  animation-duration: 1s;
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background: $green-color;
}

.mat-tab-group.mat-primary .mat-tab-label.mat-tab-label-active, .mat-tab-nav-bar.mat-primary .mat-tab-label.mat-tab-label-active{
  background-color: rgba($green-color, .2);
  font-weight: bold;
}

.mat-tab-group.mat-primary .mat-tab-label, .mat-tab-nav-bar.mat-primary .mat-tab-label{
  font-weight: normal;
  opacity: 1;
}

.mat-tab-group .mat-tab-header{
  border-bottom-style: none;
}

.tou-modal-active, .profile-modal-active, .auth-migration-form-modal-active {
  opacity: 0.0;
}

.modal-open {
  .mat-tab-body-active {
    z-index: auto !important;
  }
}

.ag-grid-expired-column-cell {
  border-radius:4px;
  padding:6px;
  background:#ffe9ec;
  color:#DC3545;
}

.ag-grid-warning-column-cell {
  border-radius:4px;
  padding:6px;
  background:#F0E68C;
  color:#343A40;
}

.ag-input-field-input {
  color: $sms-blue !important;
}

.dark-blue-info-popover {
  color: $card-text !important;
}

span.ag-paging-page-size {
  display: none;
}