@import 'sms-colors';
@import 'mixins';

$form-max-width: 450px;

form label {
  font-weight: 600;
  font-size: .750rem;
}

label {
  display: block;
  margin-bottom: .5rem;
}

.form-check-label {
    margin-bottom: 0px;
  }

.subform {
  max-width: $form-max-width;
}

input#title, .btn-group.bootstrap-select.form-control {
  max-width: $form-max-width;
  display: block;
}

select.form-control.selectpicker {
  max-width: $form-max-width;
  display: block !important;
}

.large-input {
  max-width: $form-max-width;
  display: block;
}

.medium-input {
  max-width: 220px;
}

.small-input {
  max-width: 150px;
}

.month-input {
  max-width: 50px;
}

.year-input {
  max-width: 65px;
}

label.small {
  font-size: 80%;
  font-weight: 400;
}

.form-group {
  position: inherit;
  padding-top: 5px;
  max-width: $form-max-width;
  margin-bottom: 5px;
}

.req:after {
  content: "*";
  color: $error-text;
  margin-left: 5px;
  font-size: 1.2em;
}

.form-group label {
  display: inline-block;
  margin-bottom: .5rem;
}

.ng-select.ng-select-disabled>.ng-select-container {
  background-color: $input-background-color;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  line-height: 1.25;
  color: $gray-text;
  background-color: $white-background;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem;
  height: calc(1.5em + .75rem + 2px);
}

.form-control.disabled, .form-control:disabled {
  background-color: $disabled-background-color;
  color: $disabled-color;
}

.form-control.field-error {
  border-color: $error-border;
  background-color: $error-background;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

.subform {
  width: 100%;
  max-width: $form-max-width;
}

.has-danger .ng-select-container {
  background: $error-background;
  border-color: $error-border;
}

.has-danger form .ng-select-container {
  background: $white-background;
  border: 1px solid rgba(0, 0, 0, .15);
}

.has-danger form .has-danger .ng-select-container {
  background: $error-background;
  border-color: $error-border;
}

.has-danger .form-control {
  background: $error-background;
  border-color: $error-border;
}

.needsMoreInfo.form-control {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

ng-select.custom > .ng-dropdown-panel > .ng-dropdown-footer {
  border-top: 1px solid #eee;
  padding: 11px;
  text-align: center;
}

ng-select.custom > .ng-dropdown-panel > .ng-dropdown-header {
  padding: 8px 10px;
  label {
    color: #347AB7;
    font-weight: 600;
    font-size: 1rem;
    padding-left: 5px;
    margin-bottom: 0px;
    cursor: pointer;
  }
}

ng-select input {
  font-size: 1rem;
}

.error-message {
  color: $error-text;
  font-size: .8em;
  text-align: right;
}

.error-text {
  color: $error-text;
}

.needs-additional-info {
  color: $error-text;
  font-size: .8em;
}

.form-group.has-danger {
  .small-input + .error-message {
    max-width: 150px;

    @include mq ('smallest') {
      width: 100%;
      right: 10px;
    }
  }

  .medium-input + .error-message {
    max-width: 250px;
  }
}

.form-group-error ng-select.custom div.ng-select-container {
  background: $error-background;
  border-color: $error-border;
}

.status-gray {
  color: $status-gray;
}

.validation {
  font-style: italic;
  color: $italics-text;
}

.validation i {
  color: $cta-green;
}

.validation i.status-gray {
  color: $status-gray;
}

textarea#textAreaDescription {
  max-width: 600px;
}

/* boostrap custom selects */

.bootstrap-select > .dropdown-toggle {
  color: $gray-text;
  border: 1px solid rgba(0, 0, 0, .15);
}

.bootstrap-select.btn-group .dropdown-menu.inner {
  display: block;
}

.bootstrap-select.btn-group .dropdown-menu.inner li a {
  padding: 6px 15px;
  display: block;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a {
  padding-left: 38px;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: inline-block !important;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.check-mark {
  position: absolute;
  left: 15px;
  right: auto;
  margin-top: 2px !important;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.check-mark:after {
  content: "";
  font-family: FontAwesome;
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  font-size: .75rem;
  text-align: center;
  line-height: 1.4;
  color: $white-accent;
  border: 1px solid #E6E8ED;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark:after {
  content: "\f00c";
  border-color: transparent;
  background: $p-color;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  left: 15px;
  right: auto;
  margin-top: 2px !important;
}

.dropdown-header {
  font-size: .75rem;
  font-weight: 600;
}

/* END custom selects */

/* sliders / toggles */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $input-background-color;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: $white-background;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $cta-green;
}

input:focus + .slider {
  box-shadow: 0 0 1px $cta-green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/* END sliders / toggles */

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  margin-left: 30px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-left: 5px;
}

.edit-actions button {
  margin-left: 20px;
}

.froala-class {
  width: 650px;

  @include mq ('medium') {
    width: 100%;
  }

  @include mq ('small') {
    width: 100%;
  }

  @include mq ('smallest') {
    width: 100%;
  }
}

.modal-content {
  .froala-class {
    width: 100%;
  }
}

// styles for multi-selects with long displayText
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 0.89em;
  margin-right: 3px;
  margin-bottom: 5px;
  background-color: #f5faff;
  border-radius: 2px;
  border: 1px solid #c2e0ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 0px 3px 0px 3px;
  vertical-align: top;
  border-left: 1px solid #c2e0ff;
  white-space: normal;
}

// Override the line between the "x" and the displayText so it is on the label instead of the icon
// so it extends multi-lines when the displayText wraps.
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none;
}

section.edit-actions.text-end {
  width: calc(100vw - 315px);
  z-index: 1061;

  @include mq ('medium') {
    width: 100%;
  }

  @include mq ('small') {
    width: 100%;
  }

  @include mq ('smallest') {
    width: 100%;
  }
}

section.edit-actions.text-end.sticky {
  position: fixed;
  bottom: 0;
  background: $white-background;
  display: block;
  z-index: 1061;
  width: calc(100% - 250px);
  left: 250px;
  padding: 20px;
  box-shadow: 0 0px 12px rgba(0, 0, 0, 0.25);
  display: block;

  @include mq ('medium') {
    width: 100%;
    left: 0;
  }

  @include mq ('small') {
    width: 100%;
    left: 0;
  }

  @include mq ('smallest') {
    width: 100%;
  }

  div.col-lg-8 {
    position: relative;
  }
}

.fa {
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-info-circle {
  color: $links-blue;
}

.fa-info-circle.info-popover {
  margin-left: 5px;
}

.fa-ellipsis-h:before, .fa-ellipsis:before {
    font-size: 17px;
}

.magic-item {
  margin-left: 4px;
}

/* New Toggle Switch */
.switch {
	position: relative;
	display: block;
	vertical-align: top;
	width: 100px;
	height: 30px;
	padding: 3px;
	margin:0;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
	border-radius: 18px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
  box-sizing:content-box;
}
.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
  box-sizing:content-box;
}
.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 10px;
	text-transform: uppercase;
	background: #eceeef;
	border-radius: inherit;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  box-sizing:content-box;
}
.switch-label:before, .switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
  box-sizing:content-box;
}
.switch-label:before {
	content: attr(data-off);
	right: 38px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
	content: attr(data-on);
	left: 38px;
	color: $white-accent;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switch-input:checked ~ .switch-label {
	background: #E1B42B;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label:before {
	opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
	opacity: 1;
}
.switch-handle {
	position: absolute;
	top: 4px;
	left: 4px;
	width: 28px;
	height: 28px;
	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
	background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
	border-radius: 100%;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
.switch-handle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
	width: 12px;
	height: 12px;
	background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
	background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
	border-radius: 6px;
	box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}
.switch-input:checked ~ .switch-handle {
	left: 74px;
	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}
/* Switch Flat
==========================*/
.switch-flat {
	padding: 0;
	background: $white-background;
	background-image: none;
}
.switch-flat .switch-label {
	background: $white-background;
	border: solid 2px #eceeef;
	box-shadow: none;
}
.switch-flat .switch-label:after {
	color: $cta-green;
}
.switch-flat .switch-handle {
	top: 6px;
	left: 6px;
	background: #dadada;
	width: 22px;
	height: 22px;
	box-shadow: none;
}
.switch-flat .switch-handle:before {
	background: #eceeef;
}
.switch-flat .switch-input:checked ~ .switch-label {
	background: $white-background;
	border-color: $cta-green;
}
.switch-flat .switch-input:checked ~ .switch-handle {
	left: 72px;
	background: $cta-green;
	box-shadow: none;
}
/* Transition
============================================================ */
.switch-label, .switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}

input::-ms-clear {
  display: none;
}

form h2
{
  padding-top: 10px;
}

.character-counter {
  padding-top: 3px;
  font-size: .67rem;
  width: 100%;
  text-align: right;
}

.form-group input[type=number]::-webkit-inner-spin-button,
.form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-group input[type=number] {
  -moz-appearance:textfield;
}

.alert-light {
  color: #818182;
  background-color: #f4f4f4;
  border-color: #fdfdfe;
}

.alert {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-radius: .25rem;
}

.ngui-auto-complete ul {
  overflow-y: auto;
  border: 0 !important;
  max-height: 200px;
}

.ngui-auto-complete ul li {
  border-right: 1px solid #818182 !important;
  border-left: 1px solid #818182 !important;
}

.ngui-auto-complete ul li:nth-of-type(1) {
  border-top: 1px solid #818182 !important;
}

.ngui-auto-complete ul li:last-of-type {
  border-bottom: 1px solid #818182 !important;
}

.ngui-auto-complete ul li.no-match-found {
  display: none;
}

.no-match-found {
  display: none;
}

.inputButtonCombo {
  display: flex;
}

.input-group-btnx {
  position: relative;
  z-index: 1000;
}

.inputButtonCombo button {
  margin-left: 8px;
}

.inputClearButton {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  font-size: unset;
  line-height: unset;
  padding: 8px 16px;
  margin: 0;
  color: unset;
}

.inputClearButton:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
  text-decoration: none;
}

.inputClearButton:after {
  content: "\f00d";
  font-weight: 300;
  font-size: .75rem;
  font-family: FontAwesome;
  color: #999999;
}

select.form-control[size], select.form-control[multiple], textarea.form-control {
    height: auto;
}